import React, { Component } from "react";
import BillingTable from "./BillingTable";
import axios from "axios";
import { connect } from "react-redux";
import {
  loadNext,
  loadBills,
  unfetchbills,
  changeRowsPerPage,
} from "./BillingStore";
import { Autocomplete } from "@material-ui/lab";
import { TablePagination } from "@material-ui/core";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";
import { DateRangePicker } from "react-dates";
import { checkAccess } from "../../helpers/RBAC";
import moment from "moment";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import withMediaQuery from "../../helpers/mediaQueryHelper";
import { Clear } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
import Header from "../../commons/Header/Header";
import plansHelper from "../../helpers/plansHelper";
import billingsHelper from "../../helpers/billingsHelper";
import { partnerURL } from "../../config/config";
import LockIcon from "@material-ui/icons/Lock";

class BillingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertType: "",
      alertMsg: "",
      openDialog: false,
      insideAssistantpage: false,
      insideAssistant: false,
      reportLoading: false,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      billingFilter: {
        sort: "dec",
      },
    };
  }

  componentDidMount() {
    const assistantId = this.props.match.params.projectId;
    if (
      !this.props.partnerBillingLogs?.length ||
      this.props.partnerBillingLogs[0].name === "Loading" ||
      this.props.billingAssistant !== assistantId
    ) {
      this.props.unfetchbills();
      this.props.loadBills(assistantId, this.state.billingFilter);
    }
    plansHelper.setPlanFamilies();
    if (assistantId != null) {
      this.setState({
        insideAssistantpage: true,
      });
    }
  }

  onSnackbarClose = () => {
    this.setState({
      alertType: "",
      alertMsg: "",
    });
  };

  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };
  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };
  applyFilter = () => {
    const { applied, billingFilter } = this.state;
    const filterObj = { ...billingFilter };
    if (applied.createdAt.startDate && applied.createdAt.endDate) {
      filterObj.fromDate = applied.createdAt.startDate._d;
      filterObj.toDate = applied.createdAt.endDate._d;
    } else {
      delete filterObj.fromDate;
      delete filterObj.toDate;
    }
    this.setState(
      {
        billingFilter: filterObj,
        openDialog: false,
      },
      () => {
        this.onTabChange();
      }
    );
  };
  onTabChange = () => {
    const assistantId = this.props.match.params.projectId;
    this.props.loadBills(assistantId, this.state.billingFilter);
  };
  loadnextpage = (event, newPage) => {
    const assistantId = this.props.match.params.projectId;
    this.props.loadNext(event, newPage, assistantId, this.state.billingFilter);
  };
  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = parseInt(event.target.value, 10);
    this.props.changeRowsPerPage(rowsPerPage);
    this.onTabChange();
  };
  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };

  getConvoReport = async (startDate, endDate, report, selectedReasonCodes) => {
    const partnerId = this.props.partner?._id;
    this.setState({
      reportLoading: true,
    });

    const endpoint =
      report === "wcc"
        ? `/partner/${partnerId}/partner-convo-report`
        : `/partner/${partnerId}/partner-billing-report`;

    const requestData =
      report === "wcc"
        ? { startDate, endDate }
        : { startDate, endDate, selectedReasonCodes };

    const defaultHeaders =
      report === "wcc"
        ? null
        : [
            "projectId",
            "projectName",
            "reasonCode",
            "amount",
            "action",
            "whatsappNumber",
            "businessContact",
            "email",
            "businessName",
            "message",
            "createdAt",
          ];

    try {
      const response = await axios.post(partnerURL + endpoint, requestData, {
        responseType: "stream",
      });

      const reportData = response.data?.logs || [];
      const headers =
        report === "wcc"
          ? Array.from(
              new Set(reportData.flatMap((entry) => Object.keys(entry)))
            )
          : defaultHeaders;

      // Convert to CSV
      const csvContent = [
        headers.join(","),
        ...reportData.map((entry) =>
          headers
            .map((header) =>
              entry[header] !== undefined && entry[header] !== null
                ? `"${entry[header].toString().replace(/\n/g, "\\n")}"`
                : ""
            )
            .join(",")
        ),
      ].join("\n");

      // Create download link
      const blob = new Blob([csvContent], { type: "text/csv" });
      const downloadLink = document.createElement("a");
      downloadLink.href = URL.createObjectURL(blob);

      const start = moment(startDate, "YYYY-MM-DD").isValid()
        ? moment(startDate).format("DD-MM-YYYY")
        : null;
      const end = moment(endDate, "YYYY-MM-DD").isValid()
        ? moment(endDate).format("DD-MM-YYYY")
        : null;

      downloadLink.download =
        report === "wcc"
          ? `Partner_Conversation_Report_${start || "Start"}_to_${
              end || "End"
            }.csv`
          : `Partner_Billing_Report_${start || "Start"}_to_${end || "End"}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      this.setState({
        reportLoading :false,
        alertType: "success",
        alertMsg: "Successfully downloaded report!",
      });
    } catch (error) {
      this.setState({
        reportLoading :false,
        alertType: "error",
        alertMsg: error?.response?.data?.message || "Error downloading report!",
      });
      console.error(error);
    }
  };
  render() {
    const {
      classes,
      mediaQuery,
      partnerBillingLogs,
      partner,
      partnerAgents,
      user,
    } = this.props;
    const { type } = partner || {};
    const bills = billingsHelper.mouldBillingMessages(this.props, type);
    const { billingFilter, applied } = this.state;
    const partnerId = partner.id;

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "billings",
      "read"
    );

    const hasWriteAccess = false;

    return (
      <div className={classes.root}>
        {hasReadAccess ? (
          <>
            {!this.props.assistants && !this.state.insideAssistantpage && (
              <Header
                businessTitle={"Billings"}
                disableFilterBtn={
                  !this.props.assistants && this.state.insideAssistantpage
                }
                toggleFilterDialog={this.toggleDialog}
                filterColor={
                  !!applied.createdAt.startDate && !!applied.createdAt.endDate
                }
                convoReportDownload={true}
                getConvoReport={this.getConvoReport}
                reportLoading={this.state.reportLoading}
                partnerId={partnerId}
                hasReadAccess={hasReadAccess}
                hasWriteAccess={hasWriteAccess}
              />
            )}
            <Box my={3} />
            {/* Layout */}
            <Grid container justifyContent="center">
              <Grid item xs={12} md={11} lg={10}>
                {/* First section */}
                {/* Scrollable (x & y) table container */}
              </Grid>
            </Grid>
            <Grid container justifyContent="center">
              <Grid item xs={12} md={11} lg={10}>
                {/* First section */}
                {/* Scrollable (x & y) table container */}
                <div>
                  {!this.props.assistants && this.state.insideAssistantpage ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography variant="body1" color="textSecondary">
                        Waba is not live yet!
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.tableContainer}>
                      <BillingTable
                        bills={bills}
                        isLoading={this.props.isLoading}
                        hasReadAccess={hasReadAccess}
                      />
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
            <div className={classes.fixedBottomContainer}>
              {!this.props.assistants && this.state.insideAssistantpage ? (
                <></>
              ) : (
                <>
                  <TablePagination
                    component="div"
                    count={this.props.totalprojects || 0}
                    page={this.props.page}
                    onPageChange={this.loadnextpage}
                    rowsPerPage={this.props.rowsPerPage}
                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              height: "80vh",
              alignItems: "center",
              // backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <LockIcon
              style={{
                fontSize: 90,
                color: "grey",
                marginBottom: "10px",
              }}
            />
            <Typography variant="h4" color="textSecondary">
              Access Denied
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Looks like you don't have the necessary permissions to view this
              page
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please contact your administrator for more information.
            </Typography>
          </Box>
        )}

        <Dialog
          open={this.state.openDialog}
          maxWidth={"xs"}
          className={classes.dialogContainer}
          fullScreen={mediaQuery}
        >
          <DialogContent style={{ height: 440 }}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                style={{ paddingRight: 0, paddingTop: 0 }}
              >
                <Typography variant="h3">Filter</Typography>
                <IconButton onClick={this.toggleDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography>Sort</Typography>
              </Grid>

              <Autocomplete
                style={{ width: 254 }}
                disableClearable
                value={
                  billingFilter.sort === "dec" ? "Descending" : "Ascending"
                }
                onChange={(event, value) => {
                  const filterObj = {
                    ...this.state.billingFilter,
                    sort: value === "Descending" ? "dec" : "asc",
                  };
                  this.setState({ billingFilter: filterObj });
                }}
                name="sort"
                options={["Descending", "Ascending"]}
                renderInput={(params) => (
                  <TextField
                    style={{ marginLeft: "4px" }}
                    {...params}
                    variant="outlined"
                    classes={{ root: classes.textFieldRoot }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingRight: 24, paddingBottom: 16 }}>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{ paddingBottom: mediaQuery && 72 }}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.clearDate("createdAt")}
                style={{ marginRight: 8 }}
              >
                Clear Filter
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.applyFilter}
              >
                Apply
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={!!this.state.alertMsg}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
          autoHideDuration={3000}
        >
          <Alert severity={this.state.alertType} onClose={this.onSnackbarClose}>
            {this.state.alertMsg}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
        <Grid item md={1}>
          {A.startDate && A.endDate ? (
            <Grid item>
              <IconButton
                color="primary"
                size="small"
                variant="filled"
                onClick={() => clearDate(filterKey)}
              >
                <Clear />
              </IconButton>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 65,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  attachTooltipPopper: {
    "& .MuiTooltip-tooltip": {
      padding: 0,
      background: "rgb(70 20 134 / 15%)",
      borderRadius: 8,
      boxShadow: "0 0 6px #c6c6c6",
    },
    "& .MuiTooltip-arrow": {
      color: "rgb(70 20 134 / 15%)",
      filter: "drop-shadow(0px -2px 1px #ddd)",
    },
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 180px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    height: 32,
    marginBottom: "20px",
    background: "white",
    border: "1px solid #d3d3d3",
    "& input": {
      padding: "0px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },

  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  passwordIcon: {
    cursor: "pointer",
    fontSize: 20,
  },

  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    paddingTop: "0px",
    // marginTop: "4px",
    height: 32,
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  textField: {
    width: 300,
    marginRight: 8,
    height: 42,
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "0px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        // height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },

  sortFormControl: {
    width: 120,
    marginRight: 8,
    height: 32,
    borderRadius: "4px",
    border: "1px solid lightgrey",
  },
});
// export default withStyles(styles)(withMediaQuery('(min-width:600px)')(Main));
const Billingconnect = connect(
  (state) => ({
    partnerBillingLogs: state.billing.partnerBillingLogs,
    isBillingLoading: state.billing.isLoading,
    skip: state.billing.skip,
    totalprojects: state.billing.totalprojects,
    billingAssistant: state.billing.assistantId,
    isLoading: state.billing.isLoading,
    error: state.billing.error,
    page: state.billing.page,
    rowsPerPage: state.billing.rowsPerPage,
    nameQuery: state.billing.nameQuery,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    isPlansLoading: state.planFamilies.isLoading,
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
    user: state.login.user,
  }),
  { loadNext, loadBills, unfetchbills, changeRowsPerPage }
)(BillingPage);

export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(withRouter(Billingconnect))
);
