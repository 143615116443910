import { React, useState } from "react";
import {
  withStyles,
  Typography,
  Grid,
  TextField,
  Button,
  InputAdornment,
  Box,
  IconButton,
  Badge,
  Tooltip,
  CircularProgress,
} from "@material-ui/core";
import {
  FilterList,
  Clear,
  ArrowBackOutlined,
  Search,
  GetApp,
  Edit,
} from "@material-ui/icons";
import DownloadReport from "../../pages/Assistants/WccAnalytics/DownloadReport";

function Header(props) {
  const {
    classes,
    businessTitle,
    buttonPrefixIcon,
    buttonTitle,
    onButtonClick,
    handleSearch,
    handleEnter,
    searchDone,
    handleClear,
    showSearch,
    searchPlaceholder,
    filter,
    disableFilterBtn,
    toggleFilterDialog,
    onBackPress,
    backgroundColor,
    filterColor,
    syncButtonTitle,
    onSyncButtonClick,
    syncButtonPrefixIcon,
    tooltipTitle,
    disableTemplateButton,
    businessArchived,
    convoReportDownload,
    getConvoReport,
    reportLoading,
    partnerId,
    clientReport,
    projectReport,
    hasWriteAccess,
    hasReadAccess,
    section,
    onEditClick,
  } = props;

  const [showReport, setShowReport] = useState(false);
  const [showBillingReport, setShowBillingReport] = useState(false);

  // Vaco
  const showEditOptions =
    hasWriteAccess &&
    ["66fe33ed494c320b70b659e6"].includes(partnerId) &&
    ["project", "business"].includes(section);

  return (
    <Grid
      container
      alignItems="center"
      className={`${classes.pageTitleContainer} ${classes.fullWidth}`}
      style={{ height: window.innerWidth < 768 && 160, backgroundColor }}
    >
      <Grid item xs={12}>
        <Box
          px={{ xs: 2, md: 2 }}
          justifyContent="space-between"
          py={2}
          display="flex"
          alignItems="center"
        >
          {onBackPress && (
            <IconButton onClick={onBackPress}>
              <ArrowBackOutlined />
            </IconButton>
          )}
          {/* business title */}
          <Typography variant="h3" style={{ flex: 1 }}>
            {businessTitle}{" "}
            {businessTitle && showEditOptions && (
              <IconButton
                size="small"
                style={{ marginLeft: 5, marginBottom: 2 }}
                onClick={onEditClick}
              >
                <Edit fontSize="small" />
              </IconButton>
            )}
          </Typography>

          {window.innerWidth >= 768 && (
            <>
              {/* Show button */}

              {syncButtonTitle && (
                <Tooltip title={tooltipTitle}>
                  <span>
                    <Button
                      onClick={onSyncButtonClick}
                      color="primary"
                      variant="contained"
                      disabled={disableTemplateButton}
                      startIcon={syncButtonPrefixIcon}
                      style={{
                        marginRight: 8,
                        height: 42,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {syncButtonTitle}
                    </Button>
                  </span>
                </Tooltip>
              )}

              {clientReport && !businessArchived && (
                <Box>
                  <Button
                    style={{
                      marginRight: 8,
                      height: 42,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color="primary"
                    onClick={() => {
                      setShowReport(true);
                    }}
                    variant="outlined"
                    disabled={!hasReadAccess}
                    startIcon={<GetApp />}
                  >
                    {window.innerWidth < 400 ? "Report" : "WCC Report"}
                  </Button>

                  <DownloadReport
                    classes={classes}
                    showReport={showReport}
                    setShowReport={setShowReport}
                    getConvoReport={getConvoReport}
                    reportLoading={reportLoading}
                    convoReportDownload={true}
                    dialogHeading={"Download Business Report"}
                    dialogBody={
                      "Select the date range to download business conversation report"
                    }
                    partnerId={partnerId}
                  />
                </Box>
              )}

              {clientReport && !businessArchived && (
                <Box>
                  <Button
                    style={{
                      marginRight: 8,
                      height: 42,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    color="primary"
                    onClick={() => {
                      setShowBillingReport(true);
                    }}
                    variant="outlined"
                    startIcon={<GetApp />}
                    disabled={!hasReadAccess}
                  >
                    {window.innerWidth < 400 ? "Billing" : "Billing Report"}
                  </Button>

                  <DownloadReport
                    classes={classes}
                    showReport={showBillingReport}
                    setShowReport={setShowBillingReport}
                    getConvoReport={getConvoReport}
                    reportLoading={reportLoading}
                    convoReportDownload={true}
                    dialogHeading={`Download ${
                      projectReport
                        ? "Project"
                        : clientReport
                        ? "Business"
                        : "Partner"
                    } Billing Report`}
                    dialogBody={
                      "Select the date range to download business billing report"
                    }
                    partnerId={partnerId}
                  />
                </Box>
              )}

              {buttonTitle && !businessArchived && hasWriteAccess && (
                <Button
                  onClick={onButtonClick}
                  color="primary"
                  variant="contained"
                  startIcon={buttonPrefixIcon}
                  // disabled={buttonTitle !== "Help Center"}
                  style={{
                    marginRight: 8,
                    height: 42,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {buttonTitle}
                </Button>
              )}

              {/* Show search textfield */}
              {showSearch && (
                <TextField
                  name="search"
                  placeholder={searchPlaceholder}
                  className={classes.textField}
                  onChange={handleSearch}
                  onKeyPress={handleEnter}
                  value={filter}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            searchDone
                              ? handleClear()
                              : handleEnter({
                                  key: "Enter",
                                  target: { value: filter },
                                })
                          }
                        >
                          {searchDone ? <Clear /> : <Search />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </>
          )}

          {/* Show Download Repoet */}

          {convoReportDownload && (
            <Box>
              <Button
                style={{
                  right: "5px",
                }}
                color="primary"
                size="small"
                onClick={() => {
                  setShowReport(true);
                }}
                variant="outlined"
                startIcon={<GetApp />}
                disabled={!hasReadAccess}
              >
                {window.innerWidth < 400 ? "WCC" : "WCC Report"}
              </Button>

              <DownloadReport
                classes={classes}
                showReport={showReport}
                setShowReport={setShowReport}
                getConvoReport={getConvoReport}
                reportLoading={reportLoading}
                convoReportDownload={convoReportDownload}
                dialogHeading={"Download Partner WCC Report"}
                dialogBody={
                  "Select the date range to download partner WCC report"
                }
                partnerId={partnerId}
              />
            </Box>
          )}

          {projectReport && (
            <Grid style={{ display: "flex", flexDirection: "row" }}>
              <Box>
                <Button
                  style={{
                    right: "5px",
                  }}
                  color="primary"
                  size="small"
                  onClick={() => {
                    setShowReport(true);
                  }}
                  variant="outlined"
                  startIcon={<GetApp />}
                  disabled={!hasReadAccess}
                >
                  {window.innerWidth < 400 ? "WCC" : "WCC Report"}
                </Button>

                <DownloadReport
                  classes={classes}
                  showReport={showReport}
                  setShowReport={setShowReport}
                  getConvoReport={getConvoReport}
                  reportLoading={reportLoading}
                  projectReport={projectReport}
                  convoReportDownload={true}
                  dialogHeading={"Download Project Report"}
                  dialogBody={
                    "Select the date range to download project conversation report"
                  }
                />
              </Box>
            </Grid>
          )}

          {(convoReportDownload || projectReport) && (
            <Box>
              <Button
                style={{
                  right: "5px",
                  marginLeft: "10px",
                }}
                color="primary"
                size="small"
                onClick={() => {
                  setShowBillingReport(true);
                }}
                variant="outlined"
                startIcon={<GetApp />}
                disabled={!hasReadAccess}
              >
                {window.innerWidth < 400 ? "Billing" : "Billing Report"}
              </Button>

              <DownloadReport
                classes={classes}
                showReport={showBillingReport}
                setShowReport={setShowBillingReport}
                getConvoReport={getConvoReport}
                reportLoading={reportLoading}
                convoReportDownload={true}
                dialogHeading={`Download ${
                  projectReport ? "Project" : "Partner"
                } Billing Report`}
                dialogBody={
                  "Select the date range to download partner billing report"
                }
                partnerId={partnerId}
              />
            </Box>
          )}

          {/* Show Filter icon */}
          {toggleFilterDialog && hasReadAccess && (
            <Badge color="primary" variant="dot" invisible={!filterColor}>
              <IconButton
                disable={disableFilterBtn}
                onClick={toggleFilterDialog}
                style={{ color: filterColor ? "#461486" : "" }}
              >
                <FilterList />
              </IconButton>
            </Badge>
          )}
        </Box>
      </Grid>
      {window.innerWidth < 768 && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "0 16px",
          }}
        >
          {/* Show button */}

          {syncButtonTitle && (
            <Tooltip title={tooltipTitle}>
              <span>
                <Button
                  onClick={onSyncButtonClick}
                  color="primary"
                  variant="contained"
                  disabled={disableTemplateButton}
                  startIcon={syncButtonPrefixIcon}
                  style={{
                    marginRight: 8,
                    height: 42,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {syncButtonTitle}
                </Button>
              </span>
            </Tooltip>
          )}

          {buttonTitle && !businessArchived && hasWriteAccess && (
            <Button
              onClick={onButtonClick}
              color="primary"
              variant="contained"
              // disabled={buttonTitle !== "Help Center"}
              style={{
                marginRight: 8,
                justifyContent: window.innerWidth <= 320 && "flex-start",
                height: 42,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {buttonTitle}
            </Button>
          )}

          {/* Show search textfield */}
          {showSearch && (
            <TextField
              name="search"
              placeholder={searchPlaceholder}
              className={classes.textField}
              onChange={handleSearch}
              onKeyPress={handleEnter}
              value={filter}
              InputProps={
                searchDone && {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear}>
                        <Clear />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              }
            />
          )}
        </Grid>
      )}
    </Grid>
  );
}

const styles = (theme) => ({
  pageTitleContainer: {
    position: "sticky",
    zIndex: 100,
    top: 0,
    height: 80,
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      // paddingTop: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "20px",
    },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },

  dialogContainer: {
    "& .MuiDialogContent-root": { overflow: "hidden" },
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "550px",
        minWidth: "100px",
        height: "500px",
        margin: "0",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "550px",
        height: "550px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },

  textField: {
    width: 210,
    // marginRight: 8,
    height: 42,
    "& .MuiIconButton-root": {
      padding: 6,
      background: "rgb(70 20 134 / 15%)",
      color: theme.palette.primary.main,
    },
    "& .MuiSvgIcon-root": {
      fontSize: 15,
    },
  },
});

export default withStyles(styles)(Header);
