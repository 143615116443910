/* eslint-disable no-undef */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert, Autocomplete } from "@material-ui/lab";
import axios from "axios";
import CreateBusiness from "./CreateBusiness";
import { withRouter } from "react-router-dom";
import {
  withStyles,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Snackbar,
  TablePagination,
  CircularProgress,
  Tabs,
  Tab,
} from "@material-ui/core";
import { DateRangePicker } from "react-dates";
import { partnerURL } from "../../config/config";
import moment from "moment";
import { Add, Clear } from "@material-ui/icons";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../../react-dates-custom.css";
import { checkAccess } from "../../helpers/RBAC";
import ClientsTable from "./ClientsTable";
import {
  loadNext,
  loadClient,
  unfetchclients,
  createnewClient,
  loadSearch,
  fetchBusinessCounts,
} from "./Clientstore";
import Header from "../../commons/Header/Header";
import plansHelper from "../../helpers/plansHelper";
import withMediaQuery from "../../helpers/mediaQueryHelper";
import { isoToDialCode } from "../../config/dialCodes";
import LockIcon from "@material-ui/icons/Lock";
class ClientPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      clientDialog: false,
      email: null,
      display_name: "",
      company: "",
      contact: "",
      currency: this.props.partner?.currency || "INR",
      password: null,
      timezone: "Asia/Calcutta GMT+05:30",
      countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
      industry: "",
      snack: false,
      companySize: "",
      showPassword: false,
      showConfirmPassword: false,
      status: null,
      statusMessage: "",
      buttonDisable: false,
      filter: "",
      searchdone: false,
      applied: {
        createdAt: {
          startDate: null,
          endDate: null,
          focus: null,
        },
      },
      clientFilter: {
        filter: "All",
        sort: "dec",
      },
      tab: "All",
      allCount: 0,
      archivedCount: 0,
    };
  }
  businessDialog = () => {
    this.setState({
      clientDialog: false,
    });
  };
  businessSnackDialog = (val, mess) => {
    this.setState({
      status: val,
      statusMessage: mess,
      snack: true,
    });
  };
  componentDidMount() {
    this.props.fetchBusinessCounts();
    this.props.unfetchclients();
    this.props.loadClient(this.state.clientFilter);
    plansHelper.setAllPlans();
  }
  handleInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  clearDate = (filterKey) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = null;
    applied[filterKey].endDate = null;
    this.setState({ applied });
  };
  checkDateInput = (filterKey) => {
    const applied = { ...this.state.applied };
    const s = applied[filterKey];
    if (s.startDate && s.endDate) {
      return;
    } else {
      this.clearDate(filterKey);
    }
  };

  setFocus = (filterKey, focusedInput) => {
    const applied = { ...this.state.applied };
    applied[filterKey].focusedInput = focusedInput;
    this.setState({ applied });
  };
  setDate = (filterKey, startDate, endDate) => {
    const applied = { ...this.state.applied };
    applied[filterKey].startDate = startDate;
    applied[filterKey].endDate = endDate;
    this.setState({ applied });
  };

  applyFilter = () => {
    const { applied, clientFilter } = this.state;
    const filterObj = { ...clientFilter };
    if (applied.createdAt.startDate && applied.createdAt.endDate) {
      filterObj.fromDate = applied.createdAt.startDate._d;
      filterObj.toDate = applied.createdAt.endDate._d;
    } else {
      delete filterObj.fromDate;
      delete filterObj.toDate;
    }
    this.setState(
      {
        clientFilter: filterObj,
        openDialog: false,
      },
      () => {
        this.onTabChange();
      }
    );
  };
  onTabChange = () => {
    this.props.loadClient(this.state.clientFilter);
  };

  handlesearch = (e) => {
    this.setState({ filter: e.target.value });
  };
  onSnackbarClose = (event) => {
    this.setState({ snack: false });
  };
  createclient = async () => {
    try {
      const partnerId = this.props.user.id;
      const {
        email,
        display_name,
        company,
        contact,
        currency,
        timezone,
        password,
      } = this.state;
      const queryObj = {
        email,
        password,
        display_name,
        company,
        contact,
        timezone,
        currency,
      };

      let response = await axios.post(
        partnerURL + `/partner/${partnerId}/business/`,
        queryObj
      );
      this.props.createnewClient(response.data);
      this.setState({ clientDialog: !this.state.clientDialog });
      this.setState({
        status: "success",
        statusMessage: "Business Created Succesfully",
      });
      this.setState({
        email: null,
        display_name: null,
        company: null,
        contact: null,
        currency: "INR",
        timezone: "Asia/Calcutta GMT+05:30",
        countryCode: isoToDialCode[this.props.partner?.isoCode] || "+91",
        industry: null,
        companySize: null,
        password: null,
      });
    } catch (error) {
      this.setState({
        buttonDisable: false,
      });
      const errorResponse = error.response;
      console.error(error);
      const errorMessage = errorResponse?.data?.message;
      this.setState({
        status: "error",
        statusMessage: errorMessage,
      });
    }
  };

  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  toggleDialog = () => {
    this.setState({ openDialog: !this.state.openDialog });
  };
  toggleclientDialog = () => {
    this.setState({ clientDialog: !this.state.clientDialog });
  };
  buttondisbale = () => {
    this.setState({ buttonDisable: !this.state.buttonDisable });
  };

  handleEnter = (e) => {
    if (e.key === "Enter") {
      if (this.state.filter === "") {
        this.props.unfetchclients();
        this.props.loadClient(this.state.clientFilter);
        this.setState({
          searchdone: false,
        });
      }
      this.props.loadSearch(this.state.filter);
      if (this.state.filter !== "") {
        this.setState({
          searchdone: true,
        });
      }
    }
  };

  handleClear = () => {
    this.props.unfetchclients();
    this.setState({
      filter: "",
    });
    this.setState({
      searchdone: false,
    });
    this.props.loadClient(this.state.clientFilter);
  };

  render() {
    const {
      classes,
      business,
      skip,
      totalBusinesses,
      loadNext,
      loadClient,
      isLoading,
      page,
      unfetchclients,
      rowsPerPage,
      createnewClient,
      fetchBusinessCounts,
      user,
      loadSearch,
      partnerAgents,
    } = this.props;

    const { clientFilter, applied, tab } = this.state;
    const { partnerPlanFamily, partnerWccFamily, mediaQuery, partner } =
      this.props;

    const hasReadAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "read"
    );

    const hasWriteAccess = checkAccess(
      partner,
      partnerAgents,
      user,
      "business",
      "write"
    );

    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    const isPasswordValid = passwordRegex.test(this.state.password);
    const clients = this.props.business.slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    const SIZE = [
      "1 - 10 Employees",
      "10 - 20 Employees",
      "20 - 50 Employees",
      "50 - 200 Employees",
      "200 + Employees",
    ];

    const SORT = ["dec", "asc"];
    const VERTICALS = [
      "Ecommerce",
      "Education",
      "Automotive",
      "IT Services",
      "Real Estate",
      "SAAS/Apps",
      "Gaming",
      "Entertainment",
      "Finance and Banking",
      "Medical and Health",
      "Hotel and Lodging",
      "Beauty, Spa and Salon",
      "Clothing and Apparel",
      "Event Planning and Service",
      "Food and Grocery",
      "Professional Services",
      "Public Service",
      "Non-profit",
      "Shopping and Retail",
      "Travel and Transportation",
      "Restaurant",
      "Other",
    ];

    const tabs = [
      {
        display: "All",
        state: "allCount",
        theme: "#f4f4f4",
      },
      {
        display: "Archived",
        state: "archivedCount",
        theme: "#f2c14e3d",
      },
    ];

    const getflag = (langcode) => {
      var first = langcode.charCodeAt(0) + 127397;
      var second = langcode.charCodeAt(1) + 127397;
      var flag = `&#${first};&#${second};`;
      const x = document.createElement("p");
      x.innerHTML = flag;
      return x.innerText;
    };

    return (
      <div className={classes.root}>
        {/* Page name & description container */}
        {hasReadAccess ? (
          <>
            <Grid container alignItems="center" className={classes.fullWidth}>
              <Grid item xs={12}>
                <Box className={classes.tabContainer}>
                  <Header
                    businessTitle={"Business"}
                    buttonPrefixIcon={<Add />}
                    buttonTitle={user.isReferral ? "" : "Create New Business"}
                    onButtonClick={this.toggleclientDialog}
                    showSearch={true}
                    searchPlaceholder={"Search business name"}
                    handleSearch={this.handlesearch}
                    handleEnter={this.handleEnter}
                    handleClear={this.handleClear}
                    searchDone={this.state.searchdone}
                    filter={this.state.filter}
                    toggleFilterDialog={this.toggleDialog}
                    hasWriteAccess={hasWriteAccess}
                    hasReadAccess={hasReadAccess}
                    filterColor={
                      !!applied.createdAt.endDate &&
                      !!applied.createdAt.startDate
                    }
                    section={"All Businesses"}
                  />

                  <Box px={{ xs: 2, md: 3 }}>
                    <Tabs
                      value={tab}
                      onChange={(event, newValue) => {
                        if (this.state.tab === newValue) return;
                        // const tab = tabs.find((i) => i.display === newValue);
                        this.setState(
                          {
                            tab: newValue,
                            // templates: tab.list,
                            // totalCount: tab.list.length,
                            clientFilter: {
                              ...this.state.clientFilter,
                              filter: newValue,
                            },
                          },
                          this.onTabChange
                        );
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                      TabIndicatorProps={{
                        className: classes.tabIndicator,
                      }}
                    >
                      {tabs.map((i) => (
                        <Tab
                          label={
                            <>
                              <Grid container justifyContent="space-between">
                                <Grid item xs={2}>
                                  <Box
                                    fontSize="16px"
                                    fontWeight="bold"
                                    textAlign="left"
                                    width="100%"
                                    style={{ marginTop: "5px" }}
                                  >
                                    {this.props[i.state]}
                                  </Box>
                                </Grid>
                              </Grid>
                              <Box
                                fontSize={"14px"}
                                width="100%"
                                textAlign="left"
                              >
                                {`${i.display}`}
                              </Box>
                            </>
                          }
                          value={i.display}
                          style={{
                            textTransform: "none",
                            alignItems: "end",
                            minWidth: 190,
                            marginLeft: 35,
                            paddingBottom: 8,
                            background: `linear-gradient(180deg, white, ${
                              this.state.tab === i.display ? i.theme : "white"
                            })`,
                          }}
                        />
                      ))}
                    </Tabs>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Box my={3} />
            {/* Layout */}
            <Grid container justifyContent="center">
              <Grid item xs={12} md={11} lg={10}>
                {/* First section */}
                {/* Scrollable (x & y) table container */}
                <div className={classes.tableContainer}>
                  <ClientsTable
                    business={clients}
                    isLoading={this.props.isLoading}
                  />
                </div>
              </Grid>
            </Grid>{" "}
            {/* Table footer fixed at bottom */}
            <div className={classes.fixedBottomContainer}>
              <TablePagination
                component="div"
                count={this.props.totalBusinesses}
                page={this.props.page}
                onPageChange={(event, newPage) => {
                  this.props.loadNext(event, newPage, this.state.clientFilter);
                }}
                rowsPerPage={this.props.rowsPerPage}
                rowsPerPageOptions={[25]}
              />
            </div>{" "}
          </>
        ) : (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              height: "80vh",
              alignItems: "center",
              // backgroundColor: "#f5f5f5",
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <LockIcon
              style={{
                fontSize: 90,
                color: "grey",
                marginBottom: "10px",
              }}
            />
            <Typography variant="h4" color="textSecondary">
              Access Denied
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              Looks like you don't have the necessary permissions to view this
              page
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              Please contact your administrator for more information.
            </Typography>
          </Box>
        )}
        <Dialog open={this.state.clientDialog}>
          <DialogContent style={{ maxWidth: 500, minWidth: 100 }}>
            {partner?.type === "DIRECT" ||
            (Object.keys(partnerPlanFamily).length > 0 &&
              Object.keys(partnerWccFamily)) ? (
              <CreateBusiness
                businessDialog={this.businessDialog}
                businessSnackDialog={this.businessSnackDialog}
                status={this.state.status}
                statusMessage={this.state.statusMessage}
              />
            ) : (
              <CircularProgress />
            )}
          </DialogContent>
        </Dialog>
        {/* Filter Dialog */}
        <Dialog
          open={this.state.openDialog}
          className={classes.dialogContainer}
          maxWidth="xs"
          fullScreen={mediaQuery}
        >
          <DialogContent style={{ height: 440 }}>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="space-between"
                alignItems="center"
                xs={12}
                style={{ paddingRight: 0, paddingTop: 0 }}
              >
                <Typography variant="h3">Filter</Typography>
                <IconButton onClick={this.toggleDialog}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <DateFilter
                  filterKey="createdAt"
                  filterName="Created At"
                  classes={classes}
                  applied={this.state.applied}
                  setDate={this.setDate}
                  setFocus={this.setFocus}
                  checkDateInput={this.checkDateInput}
                  clearDate={this.clearDate}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <Typography>Sort</Typography>
              </Grid>
              <Autocomplete
                style={{ marginLeft: 4, width: 254 }}
                disableClearable
                value={
                  this.state.clientFilter.sort === "dec"
                    ? "Descending"
                    : "Ascending"
                }
                onChange={(e, value) => {
                  const filterObj = {
                    ...this.state.clientFilter,
                    sort: value === "Descending" ? "dec" : "asc",
                  };
                  this.setState({ clientFilter: filterObj });
                }}
                name="sort"
                options={["Descending", "Ascending"]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    classes={{ root: classes.textFieldSortAutocomplete }}
                    InputProps={{ ...params.InputProps }}
                  />
                )}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              item
              container
              justifyContent="flex-end"
              xs={12}
              style={{
                paddingBottom: mediaQuery ? 72 : 16,
                paddingRight: 16,
              }}
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => this.clearDate("createdAt")}
                style={{ marginRight: 8 }}
              >
                Clear Filter
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.applyFilter}
              >
                Apply
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
        <Snackbar
          // style={{ zIndex: 50000 }}
          open={this.state.snack}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={this.onSnackbarClose}
        >
          <Alert severity={this.state.status} onClose={this.onSnackbarClose}>
            {this.state.statusMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

function DateFilter({
  filterKey,
  applied,
  setFocus,
  setDate,
  checkDateInput,
  clearDate,
  ...props
}) {
  const { classes, filterName } = props;
  const A = applied[filterKey];
  return (
    <Box mt={2} mb={2} className={classes.filterTypeContainer}>
      <Grid container alignItems="center">
        {A.startDate && A.endDate ? (
          <Grid item md={1}>
            <IconButton
              color="primary"
              size="small"
              variant="filled"
              onClick={() => clearDate(filterKey)}
            >
              <Clear />
            </IconButton>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} md={3}>
          <Typography variant="body1" style={{ marginRight: 8, minWidth: 100 }}>
            {filterName}
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <DateRangePicker
            startDatePlaceholderText="From"
            endDatePlaceholderText="To"
            onClose={() => checkDateInput(filterKey)}
            startDateId="startDate"
            endDateId="endDate"
            startDate={A.startDate}
            endDate={A.endDate}
            onDatesChange={({ startDate, endDate }) =>
              setDate(filterKey, startDate, endDate)
            }
            focusedInput={A.focusedInput}
            onFocusChange={(focusedInput) => setFocus(filterKey, focusedInput)}
            navPosition="navPositionTop"
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            customArrowIcon={null}
            screenReaderInputMessage={" "}
            small
            readOnly
            isOutsideRange={(day) => moment().diff(day) < 0}
          />
        </Grid>
      </Grid>
    </Box>
  );

  // function setToday()
}

//
const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
    // background: "red",
    // display: "flex",
    background: "rgb(249,249,249)",
    boxSizing: "border-box",
    overflowX: "hidden",
    position: "relative",
    // overflowY: "scroll"
    // paddingBottom: "80px"
  },
  pageTitleContainer: {
    // position: "sticky",
    // zIndex: 100,
    // top: 0,
    // height: 80,
    // boxSizing: "border-box",
    // [theme.breakpoints.down("md")]: {
    //   // paddingTop: "40px",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   // paddingTop: "20px",
    // },
  },
  fullWidth: {
    width: "100%",
    background: "white",
  },
  container: {
    background: "white",
    borderRadius: "8px",
  },
  attachTooltipPopper: {
    "& .MuiTooltip-tooltip": {
      padding: 0,
      backgroundColor: "white",
      color: "#222",
      boxShadow: "0 0 11px rgb(230,230,230)",
    },
    "& .MuiTooltip-arrow": {
      color: "rgb(70 20 134 / 15%)",
      filter: "drop-shadow(0px -2px 1px #ddd)",
    },
  },
  dialogContainer: {
    // zIndex: "3400 !important",
    "& .MuiPaper-root": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        // height: "100%",
        // maxHeight: "100%",
        margin: "0",
        borderRadius: "6px",
      },
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "unset",
        // maxHeight: "unset",
        // minHeight: "600px",
        margin: "unset",
        borderRadius: "6px",
      },
    },
  },
  fixedBottomContainer: {
    position: "fixed",
    height: "60px",
    borderTop: "1px solid lightgrey",
    background: "white",
    bottom: "0",
    left: "71px",
    right: "0",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      left: "0px",
      height: "50px",
    },
  },
  tableContainer: {
    top: "60px",
    height: "calc(100vh - 180px)",
    width: "100%",
    overflow: "hidden",
    // background: "red",
    boxSizing: "border-box",
    position: "sticky",
    [theme.breakpoints.down("sm")]: {
      // position top + bottombar height + bottom padding + navbar
      height: "calc(100vh - 70px - 50px - 10px - 50px)",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      boxSizing: "border-box",
    },
  },
  textFieldRoot: {
    width: "100%",
    padding: "8px 20px 10px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& input": {
      padding: "6px 12px!important",
    },
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  phoneTextFieldRoot: {
    width: "100%",
    padding: "8px 20px",
    marginTop: "4px",
    marginBottom: "20px",
    "& input": { width: "calc(100% - 80px)", marginLeft: "80px" },
    background: "white",
    border: "2px solid grey",
  },
  sortFormControl: {
    width: 120,
    marginRight: 8,
    height: 32,
    borderRadius: "4px",
    border: "1px solid lightgrey",
  },

  codeTextFieldRoot: {
    width: "90px",
    padding: "0 0 0 14px",
    position: "absolute",
    zIndex: 1,
    margin: "10px 0 0 2px",
    background: "white",
    "& input": {
      padding: "10px 0 !important",
    },
    "& button": {
      width: "12px",
      position: "relative",
      left: "7px",
      background: "white",
      borderRadius: 0,
      "&:hover": {
        background: "white",
      },
    },
    "& div": {
      paddingRight: "0px !important",
    },
  },
  passwordIcon: {
    cursor: "pointer",
    fontSize: 20,
  },

  textFieldRootAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },

  textFieldSortAutocomplete: {
    width: "100%",
    padding: "2px 8px",
    marginTop: "4px",
    marginBottom: "20px",
    background: "white",
    border: "2px solid grey",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
  textFieldPopper: {
    [theme.breakpoints.down("md")]: {
      left: "12px !important",
      minWidth: "calc(100% - 35px)",
    },
    [theme.breakpoints.up("md")]: {
      left: "calc(12.5vw - 16px) !important",
      minWidth: "calc(25vw + 54px)",
    },
  },
  textField: {
    width: 300,
    marginRight: 8,
    height: 42,
  },

  tabIndicator: {
    borderRadius: "3px 3px 0px 0px",
    height: 3,
  },

  tabContainer: {
    background: "white",
    boxShadow: "0 0 12px rgb(171 170 170)",
  },

  textFieldSortAutocomplete: {
    width: "120",
    height: 32,
    marginTop: "4px",
    marginBottom: "20px",
    "& input": {
      padding: "0px 12px!important",
    },
    background: "white",
    borderRadius: "4px",
    border: "1px solid #d3d3d3",
    "& label": {
      background: "white",
      color: "#b2b3b3 !important",
      padding: "0 6px",
      marginTop: -4,
    },
  },
});

const Clientconnect = connect(
  (state) => ({
    user: state.login.user,
    business: state.client.business,
    skip: state.client.skip,
    totalBusinesses: state.client.totalBusinesses,
    allCount: state.client.allCount,
    archivedCount: state.client.archivedCount,
    isLoading: state.client.isLoading,
    error: state.client.error,
    page: state.client.page,
    rowsPerPage: state.client.rowsPerPage,
    partnerPlanFamily: state.planFamilies.allPlanFamilies,
    partnerWccFamily: state.wccPlans.allWccPlans,
    partner: state.partner.partner,
    partnerAgents: state.partnerAgents.partnerAgents,
  }),
  {
    loadNext,
    loadClient,
    unfetchclients,
    createnewClient,
    loadSearch,
    fetchBusinessCounts,
  }
)(ClientPage);
export default withStyles(styles)(
  withMediaQuery("(max-width:600px)")(withRouter(Clientconnect))
);
