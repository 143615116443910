import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";

const CustomDialog = ({
  open,
  onClose,
  title,
  subtitle,
  content,
  onConfirm,
  confirmDisabled,
  confirmText = "Confirm",
  cancelText = "Cancel",
  classes,
  loader,
  PaperProps = { style: { height: "27%", width: "35%" } },
}) => (
  <Dialog open={open} onClose={onClose} PaperProps={PaperProps}>
    <DialogTitle className={classes.title}>{title}</DialogTitle>
    <DialogContent>
      {subtitle && (
        <Typography
          color="textSecondary"
          variant="body1"
          style={{ color: "black", marginBottom: "10px" }}
          display="inline"
        >
          {subtitle}
        </Typography>
      )}
      {content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {cancelText}
      </Button>
      <Button
        onClick={onConfirm}
        color="primary"
        variant="contained"
        disabled={confirmDisabled}
        endIcon={loader ? <CircularProgress size={20} color="primary" /> : ""}
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
