import {
  Box,
  Typography,
  Grid,
  Button,
  IconButton,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@material-ui/core";
import {
  DeleteForever,
  Edit,
  FiberManualRecord,
  Clear,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import PlanChangeDialog from "../../PlanDetails/SubscriptionPlans/PlanChangeDialog";
import WccChangeDialog from "../../PlanDetails/WccPlans/WccChangeDialog";
import moment from "moment";
import axios from "axios";
import {
  META_ADS_PARTNER,
  TENANT_ID,
  URL,
  partnerURL,
} from "../../../config/config";
import ConfirmationDialog from "../ConfirmationDialog";
import { Autocomplete } from "@material-ui/lab";
import WhatsappPaymentsChangeDialog from "../../PlanDetails/WhatsappPaymentsPlans/WhatsappPaymentsChangeDialog";
import { checkAccess } from "../../../helpers/RBAC";

export default function PlanSetup(props) {
  const [dialog, setDialog] = useState();
  const [loader, setLoader] = useState();
  const [amount, setAmount] = useState();
  const [action, setAction] = useState("ADD");
  const [projectStatus, setProjectStatus] = useState();

  const {
    classes,
    partner,
    planFamilies,
    wccPlans,
    whatsappPaymentPlans,
    assistant,
    setSnackbar,
    modifyAssistant,
    setAssistant,
    history,
    partnerAgents,
    user,
  } = props;

  const {
    isWhatsappVerified,
    activePlan,
    templateTier,
    paymentPlanId,
    familyId,
  } = assistant;

  const { type, addons: partnerAddons } = partner;

  const hasReadAccess = checkAccess(
    partner,
    partnerAgents,
    user,
    "business",
    "read"
  );

  const hasWriteAccess = checkAccess(
    partner,
    partnerAgents,
    user,
    "business",
    "write"
  );

  const toggleDialog = (key) => {
    if (dialog === key) {
      key = undefined;
    }
    setDialog(key);
  };

  useEffect(() => {
    setProjectStatus(assistant?.status);
  }, []);

  const changeSubscriptionPlan = async (newFamilyId, newPlanId) => {
    const newObj = { ...assistant };
    try {
      const response = await axios.patch(
        partnerURL +
          `/partner/${assistant.partnerId}/project/${assistant._id}/billing/switch-plan`,
        {
          newFamilyId,
          newPlanId,
        }
      );

      if (response.status === 200) {
        newObj.activePlan = newPlanId;
        newObj.familyId = newFamilyId;
        modifyAssistant(newObj._id, "familyId", newFamilyId);
        modifyAssistant(newObj._id, "activePlan", newPlanId);
        setAssistant(newObj);

        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response.data.message || "Successfully updated plan",
        });
        toggleDialog("ChangeSubscriptionPlan");
      }
    } catch (error) {
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
      });
    }
  };

  // For Trial Expired users who didn't made their api live
  const changeProjectStatus = async () => {
    const newObj = { ...assistant };
    try {
      const response = await axios.put(URL + "/api/change-project-status", {
        status: projectStatus,
        assistantId: newObj._id,
        partnerId: newObj.partnerId,
      });
      if (response.status == 200) {
        modifyAssistant(assistant._id, "status", projectStatus);
        setAssistant(newObj);
        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response.data.message || "Successfully changed",
        });
        toggleDialog("EmbeddedSignup");
      }
    } catch (error) {
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
      });
      setProjectStatus(assistant?.status);
      toggleDialog("EmbeddedSignup");
    }
  };

  const changeWccPlan = async (wccPlanId) => {
    const newObj = { ...assistant };
    try {
      const response = await axios.patch(URL + "/api/update-project-wcc-plan", {
        assistantId: assistant._id,
        wccPlanId,
      });

      if (response.status === 200) {
        newObj.templateTier = wccPlanId;
        modifyAssistant(newObj._id, "templateTier", wccPlanId);
        setAssistant(newObj);
        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response.data.message || "Successfully changed wcc plan",
        });
        toggleDialog("ChangeWccPlan");
      }
    } catch (error) {
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
      });
    }

    setLoader(false);
  };

  const changeWhatsappPaymentsPlan = async (paymentPlanId) => {
    const newObj = { ...assistant };
    try {
      const response = await axios.patch(
        URL + "/api/whatsapp-payments-plans/set-plan",
        {
          assistantId: assistant._id,
          paymentPlanId,
        }
      );

      if (response.status === 200) {
        newObj.paymentPlanId = paymentPlanId;
        modifyAssistant(newObj._id, "paymentPlanId", paymentPlanId);
        setAssistant(newObj);
        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response.data.message || "Successfully changed wcc plan",
        });
        toggleDialog("ChangeWhatsappPaymentsPlan");
      }
    } catch (error) {
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg:
          error?.response?.data?.message || "something went wrong, try again",
      });
    }

    setLoader(false);
  };

  const deleteProject = async () => {
    setLoader(true);
    await axios
      .post(URL + `/api/delete-project/${assistant._id}`)
      .then((response) => {
        if (response.data.status === "#28C153") {
          const data = { projectDeleted: true };
          history.push("/assistants", data);
        }

        throw { response: { data: { message: response.data.message } } };
      })
      .catch((error) => {
        setSnackbar({
          alert: true,
          alertType: "error",
          alertMsg: error?.response?.data?.message || "something went wrong",
        });
      });

    setLoader(false);
    toggleDialog("DeleteProject");
  };

  const reactivatePlan = async () => {
    setLoader(true);
    const newObj = { ...assistant };
    const {
      familyId,
      activePlan: defaultPlan,
      partnerId,
      _id: assistantId,
    } = assistant;

    await axios
      .patch(
        partnerURL +
          `/partner/${partnerId}/project/${assistantId}/billing/reactivate-project`,
        { familyId, defaultPlan }
      )
      .then((response) => {
        newObj.planStoppedOn = false;
        modifyAssistant(newObj._id, "planStoppedOn", false);
        setAssistant(newObj);
        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response.data.message || "plan reactivated Successfully",
        });
        toggleDialog("ReactivatePlan");
      })
      .catch((error) => {
        setSnackbar({
          alert: true,
          alertType: "error",
          alertMsg: error?.response?.data?.message || "something went wrong",
        });
      });

    setLoader(false);
  };

  const stopPlan = async () => {
    setLoader(true);
    await axios
      .patch(
        partnerURL +
          `/partner/${assistant.partnerId}/stop-project-billing/${assistant._id}`
      )
      .then((response) => {
        setSnackbar({
          alert: true,
          alertType: "success",
          alertMsg: response?.data?.message || "Plan Stopped!",
        });
        toggleDialog("StopPlan");
      })
      .catch((error) => {
        console.log(error);
        setSnackbar({
          alert: true,
          alertType: "error",
          alertMsg: error?.response?.data?.message || "something went wrong",
        });
      });

    setLoader(false);
  };

  const updateCredits = async (type, amount, action) => {
    setLoader(true);
    const newObj = { ...assistant };
    if (amount > 0 && ["ADD", "SUBTRACT"].includes(action)) {
      await axios
        .patch(
          partnerURL +
            `/partner/${assistant.partnerId}/project/${assistant._id}/billing/${type}`,
          {
            amount,
            action,
          }
        )
        .then(({ data }) => {
          if (type !== "adCredit") {
            const key =
              type === "pwc" ? "partnerWhatsappCredit" : "templateCredit";
            const credit = data.credit + amount;

            newObj[key] = credit;

            modifyAssistant(newObj._id, key, credit);
            setAssistant(newObj);
          } else {
            props.fecthAssistant();
          }

          setSnackbar({
            alert: true,
            alertType: "success",
            alertMsg: data?.message || "Successfully updated!",
          });

          toggleDialog(
            type === "adCredit"
              ? "adCredit"
              : type === "pwc"
              ? "PwcCredit"
              : "WccCredit"
          );
        })
        .catch((error) => {
          setSnackbar({
            alert: true,
            alertType: "error",
            alertMsg: error?.response?.data?.message || "something went wrong",
          });
        });
    } else {
      setSnackbar({
        alert: true,
        alertType: "error",
        alertMsg: "Invalid Amount",
      });
    }

    setLoader(false);
  };

  return (
    <Box>
      {/*
       * Project Config
       */}
      {isWhatsappVerified ? (
        <VerifiedProjectConfig
          partnerType={type}
          subscriptionPlanFamilies={planFamilies}
          wccPlanFamilies={wccPlans}
          whatsappPaymentPlans={whatsappPaymentPlans}
          partnerId={partner._id}
          toggleDialog={toggleDialog}
          assistant={assistant}
          loader={loader}
          classes={classes}
          hasReadAccess={hasReadAccess}
          hasWriteAccess={hasWriteAccess}
          partnerAddons={partnerAddons}
        />
      ) : (
        <UnverifiedProjectConfig
          deleteProject={() => toggleDialog("DeleteProject")}
          toggleEmbeddedSignup={() => toggleDialog("EmbeddedSignup")}
          projectStatus={projectStatus}
          classes={classes}
          hasWriteAccess={hasWriteAccess}
          toggleDialog={toggleDialog}
          assistant={assistant}
          partner={partner}
        />
      )}

      {/*
       * Popup Dialogs Section
       */}
      {dialog === "ChangeSubscriptionPlan" && (
        <PlanChangeDialog
          classes={classes}
          showDialog={true}
          toggleDialog={() => toggleDialog("ChangeSubscriptionPlan")}
          planFamilies={planFamilies}
          changePlan={changeSubscriptionPlan}
          defaultPlanFamily={familyId}
          defaultPlanName={activePlan}
        />
      )}
      {dialog === "ChangeWccPlan" && (
        <WccChangeDialog
          classes={classes}
          showDialog={true}
          toggleDialog={() => toggleDialog("ChangeWccPlan")}
          wccPlans={wccPlans}
          defaultPlan={templateTier}
          changePlan={changeWccPlan}
        />
      )}
      {dialog === "ChangeWhatsappPaymentsPlan" && (
        <WhatsappPaymentsChangeDialog
          classes={classes}
          showDialog={true}
          toggleDialog={() => toggleDialog("ChangeWhatsappPaymentsPlan")}
          plans={whatsappPaymentPlans}
          defaultPlan={paymentPlanId}
          changePlan={changeWhatsappPaymentsPlan}
        />
      )}
      {dialog === "DeleteProject" && (
        <ConfirmationDialog
          openDialog={true}
          closeDialog={() => toggleDialog("DeleteProject")}
          classes={classes}
          action={deleteProject}
          title={"Delete Project"}
          loader={loader}
          body={
            <Typography
              variant="body1"
              style={{ color: "black" }}
              display="inline"
            >
              Are you sure that you want to delete this project?
            </Typography>
          }
        />
      )}
      {dialog === "ReactivatePlan" && (
        <ConfirmationDialog
          openDialog={true}
          closeDialog={() => toggleDialog("ReactivateProject")}
          classes={classes}
          action={reactivatePlan}
          title={"Reactivate Project"}
          loader={loader}
          body={
            <Typography
              variant="body1"
              style={{ color: "black" }}
              display="inline"
            >
              Are you sure that you want to reactivate this project?
            </Typography>
          }
        />
      )}
      {dialog === "StopPlan" && (
        <Dialog
          open={true}
          closeDialog={() => toggleDialog("StopPlan")}
          maxWidth={"xs"}
        >
          <Grid
            item
            container
            justifyContent="flex-end"
            xs={12}
            style={{
              height: 30,
              marginBottom: 10,
              marginTop: 4,
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: 16,
              paddingRight: 8,
            }}
          >
            <Typography variant="h3">Stop Project</Typography>
            <IconButton onClick={() => toggleDialog("StopPlan")}>
              <Clear />
            </IconButton>
          </Grid>
          <Box
            style={{ padding: "24px 16px 16px 24px" }}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Grid container justify="center">
              <Grid item xs={12} style={{ display: "flex" }}>
                <Typography variant="h4">
                  Are you sure you want disable this project? If you disable
                  this project then all of its services will be terminated
                  permanently.
                </Typography>
              </Grid>
              <Box
                width="100%"
                height="1em"
                style={{ height: "64px", width: "48px" }}
              ></Box>
            </Grid>
            <DialogActions style={{ padding: 0 }}>
              <Button
                variant="outlined"
                size="medium"
                color="primary"
                onClick={() => toggleDialog("StopPlan")}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                size="medium"
                color="primary"
                onClick={stopPlan}
                disabled={loader}
                endIcon={loader ? <CircularProgress size={20} /> : ""}
              >
                Stop Plan
              </Button>
            </DialogActions>
          </Box>
        </Dialog>
      )}
      {dialog === "adCredit" && (
        <Dialog
          open={true}
          maxWidth="xs"
          onClose={() => toggleDialog(dialog)}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={() => toggleDialog(dialog)}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>action</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["ADD", "SUBTRACT"]}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        setAction(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="amount"
                    placeholder=" Your amount here"
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: partner?.currency === "USD" ? "$" : "₹",
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value ?? 0);
                      if (value > 0) setAmount(value * 100000);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => updateCredits("adCredit", amount, action)}
                style={{ margin: 16 }}
                disabled={loader}
                endIcon={loader ? <CircularProgress size={20} /> : ""}
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {["PwcCredit", "WccCredit"].includes(dialog) && (
        <Dialog
          open={true}
          maxWidth="xs"
          onClose={() => toggleDialog(dialog)}
          aria-labelledby="form-dialog-title"
          className={classes.plandialogContainer}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid
                item
                container
                justifyContent="flex-end"
                xs={12}
                style={{ height: 30, marginBottom: 10 }}
              >
                <IconButton onClick={() => toggleDialog(dialog)}>
                  <Clear />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{ alignItems: "center", marginTop: "15px" }}
              >
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Action</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["ADD", "SUBTRACT"]}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        setAction(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={2} style={{ marginBottom: "20px" }}>
                  <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: "20px" }}>
                  <TextField
                    name="amount"
                    placeholder=" Your amount here"
                    type="number"
                    style={{ width: "100%" }}
                    InputProps={{
                      startAdornment: partner?.currency === "USD" ? "$" : "₹",
                    }}
                    onChange={(e) => {
                      const value = Number(e.target.value ?? 0);
                      if (value > 0) setAmount(value * 100000);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid item container justifyContent="flex-end" xs={12}>
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  updateCredits(
                    dialog === "PwcCredit" ? "pwc" : "wcc",
                    amount,
                    action
                  )
                }
                style={{ margin: 16 }}
                disabled={loader}
                endIcon={loader ? <CircularProgress size={20} /> : ""}
              >
                Submit
              </Button>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {dialog === "EmbeddedSignup" && (
        <>
          <Dialog
            open={true}
            maxWidth="xs"
            onClose={() => toggleDialog(dialog)}
            aria-labelledby="form-dialog-title"
            className={classes.plandialogContainer}
          >
            <DialogContent>
              <Grid container spacing={2}>
                <Grid
                  item
                  container
                  justifyContent="center"
                  xs={12}
                  style={{ height: 30, fontWeight: "bold" }}
                >
                  Warning
                </Grid>
                <Grid
                  container
                  style={{
                    alignItems: "center",
                    marginTop: "15px",
                    padding: "10px 26px 0px 26px ",
                  }}
                >
                  <Grid item xs={12} sm={12} style={{ marginBottom: "20px" }}>
                    <Typography style={{ textAlign: "center" }}>
                      {projectStatus == "active"
                        ? `${"Enabling will make the dashboard and the embedded signup 'Apply' button visible. After the user completes the Facebook sign-up, default plans will be automatically assigned to the user's account, resulting in plan charges being deducted from your central balance."}`
                        : projectStatus == "stopped"
                        ? `${"Users will NOT be able to see the embedded Facebook signup button. The dashboard will be closed"}`
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                  <Autocomplete
                    disableClearable
                    autoHighlight
                    options={["active", "stopped"]}
                    value={projectStatus}
                    onChange={(e, value, reason) => {
                      if (reason === "select-option") {
                        setProjectStatus(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        InputProps={{ ...params.InputProps }}
                        placeholder="Select an action"
                        name="action"
                      />
                    )}
                    style={{ width: "calc(100%)", paddingTop: "0px" }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions style={{ paddingTop: "0px" }}>
              <Grid item container justifyContent="center" xs={12}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => toggleDialog(dialog)}
                  style={{ margin: "16px 0px 16px 16px" }}
                >
                  cancel
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={changeProjectStatus}
                  style={{ margin: 16 }}
                  disabled={loader || assistant.status == projectStatus}
                  endIcon={loader ? <CircularProgress size={20} /> : ""}
                >
                  Change
                </Button>
              </Grid>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

function PlanColumn(props) {
  const { title, color = "#461486" } = props;
  return (
    <Typography variant="h4" color="primary" noWrap>
      <FiberManualRecord
        style={{
          color,
          fontSize: 16,
          position: "relative",
          marginTop: 20,
          top: 3,
          right: 3,
        }}
      />
      {title}
    </Typography>
  );
}

function PlanRow(props) {
  const { title, value, showIcon, handleClick, Icon, classes, hasWriteAccess } =
    props;
  return (
    <Grid container style={{ marginBottom: "30px" }}>
      <Grid item xs={12} md={4} className={classes.cells}>
        {title}
      </Grid>
      <Grid item xs={12} md={4} className={classes.cells}>
        {value}
      </Grid>

      <Grid item xs={12} md={4} className={classes.cells}>
        {showIcon && (
          <IconButton
            style={{ marginTop: -14 }}
            disabled={!hasWriteAccess}
            onClick={handleClick}
          >
            {Icon}
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
}

function ActivePlan(props) {
  const {
    title,
    activeFamily,
    activePlan,
    planStoppedOn,
    families,
    toggleDialog,
    familyType,
    isWhatsappVerified,
    classes,
    hasWriteAccess,
  } = props;

  return (
    <Box>
      <PlanColumn
        title={title}
        color={isWhatsappVerified ? "#08CF65" : "#ff8c00"}
      />
      <Box mt={2} />
      <PlanRow
        title={"Active Plan Family"}
        value={activeFamily || "NA"}
        showIcon={!planStoppedOn && Object.keys(families).length >= 1}
        hasWriteAccess={hasWriteAccess}
        handleClick={() => toggleDialog(familyType)}
        Icon={<Edit />}
        classes={classes}
      />

      {activePlan && (
        <PlanRow
          title={"Active Plan"}
          value={activePlan}
          hasWriteAccess={hasWriteAccess}
          showIcon={!planStoppedOn && Object.keys(families).length >= 1}
          handleClick={() => toggleDialog(familyType)}
          Icon={<Edit />}
          classes={classes}
        />
      )}
    </Box>
  );
}

function VerifiedProjectConfig(props) {
  const {
    partnerType,
    subscriptionPlanFamilies,
    wccPlanFamilies,
    whatsappPaymentPlans,
    partnerId,
    toggleDialog,
    assistant,
    loader,
    classes,
    hasReadAccess,
    hasWriteAccess,
    partnerAddons,
  } = props;

  const {
    planRenewalOn,
    isWhatsappVerified,
    status,
    chargebeePlan,
    planStoppedOn,
    partnerWhatsappCredit,
    templateCredit,
    metaAdsCredit,
    familyId,
    templateTier,
    activePlan,
    paymentPlanId,
    currency,
  } = assistant;

  const subscriptionFamily = subscriptionPlanFamilies[familyId];
  const subscriptionFamilyName = subscriptionFamily?.name || "NA FAMILY";
  const subscriptionFamilyActivePlanName =
    subscriptionFamily?.plans?.[activePlan]?.name || "NA PLAN";
  const wccPlanName = wccPlanFamilies?.[templateTier]?.name ?? "NA PLAN";
  const whatsappPaymentPlanName =
    whatsappPaymentPlans?.[paymentPlanId]?.name ?? "NA PLAN";

  const { WHATSAPP_PAY } = partnerAddons || {};

  return (
    <Box>
      <Box padding={4}>
        {!chargebeePlan && partnerType !== "DIRECT" && hasReadAccess && (
          <>
            <ActivePlan
              title={"Subscription Plan Family"}
              activeFamily={subscriptionFamilyName}
              activePlan={subscriptionFamilyActivePlanName}
              planStoppedOn={planStoppedOn}
              families={subscriptionPlanFamilies}
              toggleDialog={() => toggleDialog("ChangeSubscriptionPlan")}
              familyType={"ChangeSubscriptionPlan"}
              isWhatsappVerified={isWhatsappVerified}
              classes={classes}
              hasWriteAccess={hasWriteAccess}
            />

            <ActivePlan
              title={"WhatsApp Conversation Charges (WCC) Family"}
              activeFamily={wccPlanName}
              planStoppedOn={planStoppedOn}
              families={wccPlanFamilies}
              toggleDialog={() => toggleDialog("ChangeWccPlan")}
              familyType={"ChangeWccPlan"}
              isWhatsappVerified={isWhatsappVerified}
              classes={classes}
              hasWriteAccess={hasWriteAccess}
            />

            {WHATSAPP_PAY?.status === "active" && (
              <ActivePlan
                title={"WhatsApp Payments Plan Family"}
                activeFamily={whatsappPaymentPlanName}
                planStoppedOn={planStoppedOn}
                families={whatsappPaymentPlans}
                toggleDialog={() => toggleDialog("ChangeWhatsappPaymentsPlan")}
                familyType={"ChangeWhatsappPaymentsPlan"}
                isWhatsappVerified={isWhatsappVerified}
                classes={classes}
                hasWriteAccess={hasWriteAccess}
              />
            )}

            {/**
             * Credit Management
             */}
            <>
              <PlanColumn
                title={
                  partnerId === "62839c2733581a0fb5e71df6"
                    ? "PWC Credit"
                    : "WCC Credit"
                }
                color={isWhatsappVerified ? "#08CF65" : "#ff8c00"}
              />

              <PlanRow
                title={"Amount"}
                value={Number(
                  (partnerId === "62839c2733581a0fb5e71df6"
                    ? partnerWhatsappCredit || 0
                    : templateCredit || 0) / 100000
                ).toFixed(2)}
                showIcon={!planStoppedOn}
                handleClick={() =>
                  toggleDialog(
                    partnerId === "62839c2733581a0fb5e71df6"
                      ? "PwcCredit"
                      : "WccCredit"
                  )
                }
                hasWriteAccess={hasWriteAccess}
                Icon={<Edit />}
                classes={classes}
              />
            </>
            {currency !== "USD" && !TENANT_ID && (
              <>
                <PlanColumn
                  title={"Ad Credit"}
                  color={isWhatsappVerified ? "#08CF65" : "#ff8c00"}
                />

                <PlanRow
                  title={"Amount"}
                  value={Number((metaAdsCredit || 0) / 100000).toFixed(2)}
                  showIcon={!planStoppedOn}
                  handleClick={() => toggleDialog("adCredit")}
                  hasWriteAccess={hasWriteAccess}
                  Icon={<Edit />}
                  classes={classes}
                />
              </>
            )}
          </>
        )}

        {/* Chargebee disclaimer text */}
        {chargebeePlan && (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
              alignItems: "center",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                padding="10px 36px"
                style={{
                  background: "#f7ebffdb",
                  marginTop: 12,
                  borderRadius: "8px",
                }}
              >
                <Typography variant="h4">
                  This project's billing is being managed through ChargeBee
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        <Box mt={2} />
        {/* Settings */}
        <>
          <PlanColumn title={"Settings"} color={"#461486"} />
          <Box mt={2} />
          <PlanRow
            title={"Delete Project"}
            value={""}
            showIcon={true}
            handleClick={() => toggleDialog("DeleteProject")}
            Icon={<DeleteForever />}
            hasWriteAccess={hasWriteAccess}
            classes={classes}
          />
        </>

        <Box mt={2} />
        {/* Stop Plan */}
        {!chargebeePlan && (
          <Grid
            item
            xs={12}
            md={12}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 10,
              alignItems: "center",
            }}
          >
            <Grid
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              {planStoppedOn ? (
                <>
                  <Box
                    padding="10px 36px"
                    style={{
                      background: "#f7ebffdb",
                      marginTop: 12,
                      borderRadius: "8px",
                    }}
                  >
                    {status !== "stopped" ? (
                      <Typography variant="h4">
                        This project was requested to be stopped on{" "}
                        <strong>{moment(planStoppedOn).format("LL")}</strong>{" "}
                        all the services of this project will be disabled on{" "}
                        <strong>{moment(planRenewalOn).format("LL")}</strong>
                      </Typography>
                    ) : (
                      <Typography variant="h4">
                        This project was stopped on{" "}
                        <strong>{moment(planStoppedOn).format("LL")}</strong>{" "}
                        all the services of this project are be disabled
                      </Typography>
                    )}
                  </Box>
                  <Button
                    color="primary"
                    onClick={() => toggleDialog("ReactivatePlan")}
                    style={{
                      marginTop: "12px",
                    }}
                    variant="contained"
                    disabled={loader || !hasWriteAccess}
                    endIcon={loader && <CircularProgress size={20} />}
                  >
                    Reactivate Plan
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h4" color="primary">
                    Disable Plan
                  </Typography>
                  <Button
                    color="primary"
                    disabled={!hasWriteAccess}
                    onClick={() => toggleDialog("StopPlan")}
                    variant="contained"
                  >
                    Stop
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}

function UnverifiedProjectConfig(props) {
  const {
    deleteProject,
    classes,
    hasWriteAccess,
    assistant,
    toggleEmbeddedSignup,
    planStoppedOn,
    toggleDialog,
    partner,
    projectStatus,
  } = props;
  let { planRenewalOn, planActivatedOn } = assistant;
  planRenewalOn = moment(planRenewalOn).startOf("day");
  planActivatedOn = moment(planActivatedOn).startOf("day"); // difference in milliseconds
  const diffInDays = planRenewalOn.diff(planActivatedOn, "days");

  const { currency, metaAdsCredit } = assistant;
  const { enableMetaAdsWithoutAPI } = partner;

  return (
    <Box>
      {diffInDays == 7 && hasWriteAccess && (
        <>
          {" "}
          <PlanColumn title={"Toggle project status"} color={"#461486"} />
          <Box mt={2} />
          <PlanRow
            title={
              "Reinitiate Project to allow users to make their waba live after trial is expired"
            }
            value={projectStatus == "active" ? "Active" : "Stopped"}
            showIcon={true}
            handleClick={toggleEmbeddedSignup}
            Icon={<Edit />}
            classes={classes}
            hasWriteAccess={hasWriteAccess}
          />
        </>
      )}
      <PlanColumn title={"Settings"} color={"#461486"} />
      <Box mt={2} />
      <PlanRow
        title={"Delete Project"}
        value={""}
        showIcon={true}
        handleClick={deleteProject}
        Icon={<DeleteForever />}
        classes={classes}
        hasWriteAccess={hasWriteAccess}
      />
      {enableMetaAdsWithoutAPI && currency !== "USD" && (
        <>
          <PlanColumn title={"Ad Credit"} color={"#461486"} />

          <PlanRow
            title={"Amount"}
            value={Number((metaAdsCredit || 0) / 100000).toFixed(2)}
            showIcon={!planStoppedOn}
            handleClick={() => toggleDialog("adCredit")}
            hasWriteAccess={hasWriteAccess}
            Icon={<Edit />}
            classes={classes}
          />
        </>
      )}
      <Box mt={2} />
      <Box mt={4} textAlign="center">
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ padding: 20 }}
        >
          Waba is not live yet!
        </Typography>
      </Box>
    </Box>
  );
}
